/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-unassigned-import
import './index.scss';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {pageEvents} from '@mol-fe/mol-fe-tracking';

const loaded = (async () => {
  try {
    await later('DOM_READY');

    const products = Array.from(document.querySelectorAll('.mol-product'));
    const isShoppingChannel = window.PageCriteria && ['shoppingus', 'shoppinguk'].includes(window.PageCriteria.channel);

    products
      .forEach((productElement) => {
        const header = productElement.querySelector('.mol-product-header');
        const footer = productElement.querySelector('.mol-product-footer');
        const cta = productElement.querySelector('.mol-product-cta');
        const headerText = productElement.getAttribute('data-product-title') ||
          header.textContent || header.innerText || '';
        const productName = headerText.toLowerCase().trim()
          .replace(/\s/g, '_').replace(/[^0-9a-zA-Z_]/g, '')
          .substring(0, 215);

        header.addEventListener('click', () => pageEvents.publish(pageEvents.PRODUCT_TITLE_LINK, {productName}));
        footer.addEventListener('click', () => pageEvents.publish(pageEvents.PRODUCT_PRICE_LINK, {productName}));
        cta.addEventListener('click', () => pageEvents.publish(pageEvents.PRODUCT_BUTTON_LINK, {productName}));

        if (isShoppingChannel) {
          productElement.dataset.track = 'molcommerce';
          const links = [...productElement.querySelectorAll('a')];

          for (const link of links) {
            link.dataset.track = 'molcommerce';
          }
        }
      });

    if (isShoppingChannel) {
      const bodyLinks = [...document.querySelectorAll('p.mol-para-with-font a[href*="https://"]')]
        .filter((linkEl) => !linkEl.href.startsWith(location.origin) && !linkEl.href.match(/^https:\/\/(www\.)?(dailymail|thisismoney)/i));

      for (const bodyLink of bodyLinks) {
        bodyLink.dataset.track = 'molcommerce';
      }
    }

    return products;
  } catch (error) {
    logger.error('Error during mol-fe-products initialisation: ', error);

    return null;
  }
})();

export {loaded};
