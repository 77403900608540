(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mol-fe/mol-fe-sync-bundle")["molFeAsync"], require("@mol-fe/mol-fe-sync-bundle")["molFeClientLogger"], require("@mol-fe/mol-fe-sync-bundle")["molFeComponents"], require("@mol-fe/mol-fe-sync-bundle")["molFeDomHelpers"], require("@mol-fe/mol-fe-sync-bundle")["molFeMiniLodash"], require("@mol-fe/mol-fe-sync-bundle")["molFeMobileutils"], require("@mol-fe/mol-fe-sync-bundle")["molFePageMetadata"], require("@mol-fe/mol-fe-sync-bundle")["molFeTracking"], require("@mol-fe/mol-fe-sync-bundle")["molFeXpModuleDataUtils"]);
	else if(typeof define === 'function' && define.amd)
		define([, , , , , , , , ], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@mol-fe/mol-fe-sync-bundle")["molFeAsync"], require("@mol-fe/mol-fe-sync-bundle")["molFeClientLogger"], require("@mol-fe/mol-fe-sync-bundle")["molFeComponents"], require("@mol-fe/mol-fe-sync-bundle")["molFeDomHelpers"], require("@mol-fe/mol-fe-sync-bundle")["molFeMiniLodash"], require("@mol-fe/mol-fe-sync-bundle")["molFeMobileutils"], require("@mol-fe/mol-fe-sync-bundle")["molFePageMetadata"], require("@mol-fe/mol-fe-sync-bundle")["molFeTracking"], require("@mol-fe/mol-fe-sync-bundle")["molFeXpModuleDataUtils"]) : factory(root["DM"]["molFeAsync"], root["DM"]["molFeClientLogger"], root["DM"]["molFeComponents"], root["DM"]["molFeDomHelpers"], root["DM"]["molFeMiniLodash"], root["DM"]["molFeMobileutils"], root["DM"]["molFePageMetadata"], root["DM"]["molFeTracking"], root["DM"]["molFeXpModuleDataUtils"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__4051__, __WEBPACK_EXTERNAL_MODULE__8895__, __WEBPACK_EXTERNAL_MODULE__281__, __WEBPACK_EXTERNAL_MODULE__5323__, __WEBPACK_EXTERNAL_MODULE__5497__, __WEBPACK_EXTERNAL_MODULE__1401__, __WEBPACK_EXTERNAL_MODULE__5709__, __WEBPACK_EXTERNAL_MODULE__623__, __WEBPACK_EXTERNAL_MODULE__5885__) => {
return 