/* eslint-disable complexity */
import {logger} from '@mol-fe/mol-fe-client-logger';
import {pageEvents} from '@mol-fe/mol-fe-tracking';

// determine when to display and hide sticky bar

const handleStickyBarDisplay = (product = null) => {
  const articleBody =
    document.querySelector('[itemProp="articleBody"]') ||
    document.querySelector('article');
  const stickyBar = articleBody.querySelector('.mol-products-sticky-bar');
  const stickyBarPlaceholder = articleBody.querySelector(
    '.mol-products-sticky-bar-placeholder'
  );

  const firstParagraph = articleBody.querySelector('p');

  // use onscroll instead of IntersectionObserver
  // to avoid multiple entries affecting each other
  window.addEventListener('scroll', () => {
    const belowProduct = (product || firstParagraph).getBoundingClientRect().bottom <= 0;
    const aboveArticleEnd =
      stickyBarPlaceholder.getBoundingClientRect().bottom -
        window.innerHeight >=
      0;

    if (belowProduct && aboveArticleEnd) {
      stickyBar.classList.add('sticky');
    } else if (stickyBar.classList.contains('sticky')) {
      stickyBar.classList.remove('sticky');
    }
  });
};

const handleTrackingEvents = (product = null) => {
  try {
    // select button from sticky bar
    const cta = document.querySelector(
      '.mol-product-sticky-cta-wrapper .mol-product-cta'
    );
    const header = product && product.querySelector('.mol-product-header');
    const headerText =
      product && product.getAttribute('data-product-title') ||
      header && header.textContent ||
      header && header.innerText ||
      '';

    // sanitize product name
    const productName = headerText
      .toLowerCase()
      .trim()
      .replace(/\s/g, '_')
      .replace(/[^0-9a-zA-Z_]/g, '')
      .substring(0, 215);

    cta.addEventListener('click', () =>
      pageEvents.publish(pageEvents.PRODUCT_BUTTON_LINK, {
        location: 'bar',
        productName
      })
    );
  } catch (error) {
    logger.error('Something went wrong with event tracking');
  }
};

export const stickProductButton = (productContainer = null, override = null) => {
  if (!productContainer && !override) {
    return;
  }

  const product = productContainer && productContainer.querySelector('.mol-product');
  const productClassName = productContainer && productContainer.className.replace(
    'mol-products-module',
    ''
  );
  const articleBody =
    document.querySelector('[itemProp="articleBody"]') ||
    document.querySelector('article');
  const cta = product && product.querySelector('.mol-product-cta');
  const url = override && override.buttonUrl || product && product.dataset.stickyProductUrl || cta && cta.href;
  const text = override && override.buttonText || cta && cta.innerText || 'Shop';
  const buttonClassName = cta && cta.classList || 'mol-product-cta wocc';
  const isShoppingChannel = window.PageCriteria && ['shoppingus', 'shoppinguk'].includes(window.PageCriteria.channel);
  const extraTrackAttr = isShoppingChannel ? 'data-track="molcommerce"' : '';

  if (!url || !text) {
    return;
  }

  const stickyButton = `<a class="${buttonClassName}" ${extraTrackAttr} target="_blank" href="${url}">${text}</a>`;
  const stickyBar = `
  <div class="mol-products-sticky-bar-placeholder">
    <div class="mol-products-sticky-bar ${productClassName || ''}">
      <div class="mol-product-sticky-cta-wrapper">
        ${stickyButton}
      </div>
    </div>
    </div>`;

  articleBody.insertAdjacentHTML('beforeend', stickyBar);
  handleStickyBarDisplay(product);
  handleTrackingEvents(product);
};
