import {getPageMetadata, getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {getMailto} from '@mol-fe/mol-fe-social/src/networks/email';

const getCookie = (cname) => {
  const name = cname + '=';
  const ca = decodeURIComponent(document.cookie).split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

export const createFloatingBarContainer = () => {
  const iconLinksContainer = document.getElementById(
    'articleIconLinksContainer'
  );
  const commentsCountEl = iconLinksContainer.querySelector(
    '.comments-count .count-number'
  );
  const commentsCount = commentsCountEl && commentsCountEl.innerHTML;

  const {articleStyle, showSnapchat} = getPageCriteria();
  const isArticleStyleNarrow = articleStyle === 'narrow';
  const showCommentCount = articleStyle !== 'narrow' && commentsCount;
  const showSnapchatButton = showSnapchat === 'true';
  const isTim = getPageMetadata().domain === 'thisismoney';
  const canonicalMeta = document.querySelector('link[rel="canonical"]');
  const articleLink =
    canonicalMeta && canonicalMeta.href || window.location.href;
  const container = document.createElement('DIV');
  const isTwitterLayout = getCookie('social-share-platform') === 'twitter';
  const commentCountHtml = `
    <a class="comments-count home" href="${articleLink}#comments"> <span></span>
      <p class="count-number">${commentsCount}</p>
      <p class="count-text">View <br> comments</p>
    </a>
  `;

  container.id = 'floating-share-bar-container';
  container.setAttribute('data-preferred-shared-network-enabled', '');
  container.innerHTML = `
   <ul>
        ${isTwitterLayout ?
    '<li class="share-twitter-long"></li><li class="share-facebook"></li> ' :
    '<li class="share-facebook-long"></li><li class="share-twitter"></li>'}
         <li class="share-fbmessenger">
             <a class="js-tl" rel=""></a>
         </li>
         ${isArticleStyleNarrow || !showSnapchatButton ? '' : '<li class="share-snapchat"><a class="js-tl" rel=""></a></li>'}
         <li class="share-email">
      <a rel="nofollow noreferrer noopener" target="_blank" href="${getMailto('email-plain-text', null, 'floatingBar', null)}">
                 <span class="wai">e-mail</span>
             </a>
         </li>
    </ul>
  <div class="floating-buttons-bar">
     <a class="back-to-top" href="#top"><span class="top-chevron">Top</span></a>
     <a class="back-to-home new-articles ${isTim ? 'tim-bgr' : ''}" href="/">
     <div class="new-article-count"></div>
       <span>Home</span>
     </a>
     <a class="back-to-home hidden" href="/">
      <span>Home</span>
     </a>
  </div>

  ${showCommentCount ? commentCountHtml : ''}
  `;
  iconLinksContainer.parentNode.insertBefore(
    container,
    iconLinksContainer.nextSibling
  );
};
