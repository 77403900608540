import {logger} from '@mol-fe/mol-fe-client-logger';
import {pageEvents} from '@mol-fe/mol-fe-tracking';

const trackEvent = (eventKey, data) => {
  try {
    pageEvents.publish(eventKey, data);
  } catch (error) {
    logger.error('trackEvent: error', eventKey, error);
  }
};

export const trackCommentsOverlayOpen = () => trackEvent(pageEvents.COMMENTS_OVERLAY_OPEN);
export const trackCommentsOverlayClose = () => trackEvent(pageEvents.COMMENTS_OVERLAY_CLOSE);
